<template>
  <div class="triageList">
    <div class="tops">
      <p style="opacity: 0;width: 10vw;">000</p>
      <h3>就诊列表</h3>
      <div class="xzks">
        <p>选择医生</p>
        <el-select class="sr" @change="xzks" v-model="exid" placeholder="请选择">
          <el-option
              v-for="item in expert"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <p style="padding-left: 20px;">选择科室</p>
        <el-select class="sr" v-model="value" @change="xzks" placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button style="margin-left: 20px;" type="primary" @click="$router.push({path: '/reserveHome'})">预约手术列表</el-button>
      </div>
    </div>
    <ul class="content back">
      <li>序号</li>
      <li>姓名</li>
      <li>性别</li>
      <li>年龄</li>
      <li>联系方式</li>
      <li>预约来源</li>
      <li>预约时间</li>
      <li>挂号类别</li>
      <li>就诊内容</li>
      <li>就诊状态</li>
    </ul>
    <div class="box">
      <ul class="content" v-for="(item, idx) in dataArr">
        <li>{{idx<10?'0'+(idx+1):idx+1}}</li>
        <li>{{item.Customer.CustomerName}}</li>
        <li>{{item.Customer.CustomerGender=='0'?'女':'男'}}</li>
        <li>{{item.sr}}</li>
        <li>{{item.Customer.CustomerTel}}</li>
        <li>--</li>
        <li>{{item.OppointmentTime}}</li>
        <li>{{item.DeptNae}}</li>
        <li>{{item.OppointmentType}}</li>
        <li><el-button type="primary" size="small" @click="call(item.OppointmentId)">叫号</el-button></li>
      </ul>
    </div>
  </div>
</template>

<script>
import pb from "@/publicFn/baseFn";
import {Loading, MessageBox} from "element-ui";

export default {
  name: "triageList",
  data() {
    return {
      value: '',
      options: [],
      dataArr: [],
      expert: [],
      exid: ''
    }
  },
  created() {
    this.$store.commit('isShow/upBottom', false)
    if (!this.$store.state.users.employees) {
      return this.$alert('员工未登陆请先登陆！', '提示')
      .then(res => {this.$router.push({path: '/employeesLanding'})})
    }

    this.getData()

    this._api.userCurdApi.getEmployeesList()
        .then(res => {
          if (res.GetListResult && res.GetListResult.length > 0) {
            this.expert = res.GetListResult.map(item => {
              return {
                value: item.ExpertId,
                label: item.ExpertName
              }
            })
            // this.expertName = this.expert[0].label
          }
        })

    this._api.Company.getDept()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length) {
        let arr = []
        for (let i = 0; i < res.GetListResult.length; i++) {
          arr.push({
            value: res.GetListResult[i].DeptId,
            label: res.GetListResult[i].DeptName
          })
        }
        this.options = arr
      }
    })
  },
  methods: {
    getData() {
      this.xzks()
    },

    call(id) {
      if (!this.value) return this.$alert('请选择科室！', '提示')
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '呼叫中...'
      });
      this._api.Company.callHao(id, this.value)
          .then(res => {
            loadingInstance.close()
            if (res.msg == 'Success') {
              this.$alert('呼叫成功！', '提示')
              this.getData()
            }else  {
              this.$alert('呼叫失败！稍后再试', '提示')
            }
          })
    },

    xzks() {
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });

      this._api.Company.getTriageList(this.value, this.exid)
          .then(res => {
            loadingInstance.close()
            if (res.GetListResult && res.GetListResult.length) {
              let arr = []
              for (let i = 0; i < res.GetListResult.length; i++) {
                res.GetListResult[i].sr = pb.getYear(res.GetListResult[i].Customer.CustomerBirthday)
                arr.push(res.GetListResult[i])
              }
              this.dataArr = arr
            }else {
              this.dataArr = []
            }
          })
    }
  }
}
</script>

<style scoped lang="scss">
  .triageList {
    width: 100vw;
    height: 78vh;
    overflow: hidden;
  }
  .tops {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3vh;
    padding-bottom: 4vh;
    .xzks {
      display: flex;
      align-items: center;
      p {padding-right: .2rem}
      .sr {width: 12vw}
    }
  }

  .back {background: #000000; color: #ffffff;}
  .content {
    width: 100%;
    padding: .2rem 0;
    display: flex;
    justify-content: space-between;
    li {
      width: 10%;
    }
  }
  .box {
    width: 100vw;
    height: 63vh;
    overflow-y: auto;
  }
  .box .content:hover {background: #E5E5E5;}
  .box::-webkit-scrollbar { width: 0 !important}
</style>
